/* eslint-disable react/jsx-props-no-spreading */
import { useIsCustomDomain } from 'contexts/profile';
import { Product, Store } from 'containers/lazy';
import Skeleton from 'components/layouts/Skeleton';

export default function StorePage(props) {

  const isCustomDomain = useIsCustomDomain();
  if (isCustomDomain) {

    return (
      <Product {...props} />
    );

  }
  return (
    <Store {...props} />
  );

}

StorePage.propTypes = {};

StorePage.defaultProps = {};

StorePage.getLayout = (page) => <Skeleton page={page}/>;

export const getServerSideProps = async (ctx) => {

  const { getStoreProps } = require('containers/api/store');
  const { getProductProps } = require('containers/api/product');
  const { isCustomDomain } = require('server/lib/domain');
  const { host } = ctx.req.headers;
  const customDomain = isCustomDomain(host);
  if (customDomain) {

    return getProductProps(ctx);

  }
  return getStoreProps(ctx);

};
